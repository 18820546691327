import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Grid, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiPerson } from '../../../api/person';
import ChangeHandler from '../../../components/ChangeHandler';
import { ApiTopic } from '../../../api/topic';
import styles from './styles.module.scss';
import { ApiBig } from '../../../api/big';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import TagInput from '../../../components/TagInput';

interface PersonEditProps extends RouteComponentProps<{ id?: string }> {
}

const PersonEditView: FC<PersonEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ person, setPerson ] = useState<Partial<ApiPerson>>({});
    const [ topics, setTopics ] = useState<ApiTopic[]>([]);
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ bigError, setBigError ] = useState<string>();
    const [ bigData, setBigData ] = useState<ApiBig>();

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getPerson(id, 'topics,timeline,tags').then(({ data }) => {
                setPerson({
                    ...data,
                    topics: data.topics ? data.topics.map((t) => t.id) : [],
                });
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        api.listTopics({ limit: 999 }).then(({ data }) => {
            setTopics(data.data);
        });

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setPerson({
            ...person,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putPerson(person).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Consent saved succesfully');
            history.push('/consents');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    const checkBig = (): void => {
        setBigError(undefined);
        if (!person.bigNumber) return;
        api.checkBig(person.bigNumber.trim()).then(({ data }) => {
            setBigData(data);
        }).catch(() => setBigError('Dit BIG nummer is niet bekend in het BIG-register'))
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/consents': 'Consents',
                [`/consents/${id ? `${id}/edit` : 'create'}`]: id ? person.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} consent`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={4}>
                    <Segment isLoading={isLoading}>
                        <Form.Input
                            label="UCI"
                            name="uci"
                            onChange={handleInput}
                            value={person.uci || ''}
                        />
                        <Form.Input
                            error={errors.first_name}
                            label="First name"
                            name="firstName"
                            onChange={handleInput}
                            required
                            value={person.firstName || ''}
                        />
                        <Form.Input
                            label="Middle name"
                            name="affix"
                            onChange={handleInput}
                            value={person.affix || ''}
                        />
                        <Form.Input
                            error={errors.last_name}
                            label="Last name"
                            name="lastName"
                            onChange={handleInput}
                            required
                            value={person.lastName || ''}
                        />
                        <Form.Input
                            error={errors.email_address}
                            label="Email address"
                            name="emailAddress"
                            onChange={handleInput}
                            required
                            value={person.emailAddress || ''}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <Form.Input
                                    error={bigError}
                                    label="BIG number"
                                    name="bigNumber"
                                    onChange={handleInput}
                                    value={person.bigNumber || ''}
                                />
                            </div>
                            {person.bigNumber && person.bigNumber !== '' && (
                                <Button
                                    label="Controleren"
                                    onClick={() => checkBig()}
                                    style={{ marginLeft: '1rem' }}
                                />
                            )}
                        </div>
                        {bigData && (<>
                            <h3 style={{ margin: '1rem 0 .5rem 0' }}>Gegevens zoals gevonden in het BIG-register</h3>
                            <Table small>
                                <tbody>
                                    <Table.Row>
                                        <Table.Cell collapsing>Naam</Table.Cell>
                                        <Table.Cell>{bigData.salutation} {bigData.fullName}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell collapsing>Beroep</Table.Cell>
                                        <Table.Cell>{bigData.profession}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell collapsing>Specialisme</Table.Cell>
                                        <Table.Cell>{bigData.specialism}</Table.Cell>
                                    </Table.Row>
                                    {bigData.mention !== false && (
                                        <Table.Row>
                                            <Table.Cell collapsing>Vermelding</Table.Cell>
                                            <Table.Cell>{bigData.mentionType}</Table.Cell>
                                        </Table.Row>
                                    )}
                                    <Table.Row>
                                        <Table.Cell collapsing>Beroepsbeoefenaar</Table.Cell>
                                        <Table.Cell>
                                            {bigData.isPractioner && <FontAwesomeIcon icon={faCheckCircle} className="success" />}
                                        </Table.Cell>
                                    </Table.Row>
                                </tbody>
                            </Table>
                        </>)}
                    </Segment>
                </Grid.Column>
                <Grid.Column md={4}>
                    <Segment>
                        <Form.Group label="Zoektermen" focus>
                            <TagInput
                                onChange={(tags: string[]) => handleInput({ name: 'tags', value: tags })}
                                tags={person.tags}
                                type="person"
                            />
                        </Form.Group>
                        <Form.Dropdown
                            label="Topics"
                            multiple
                            name="topics"
                            options={topics.map((t) => ({
                                text: `${t.name}`,
                                value: t.id,
                            }))}
                            onChange={handleInput}
                            value={person.topics || []}
                        />
                    </Segment>
                </Grid.Column>
                <Grid.Column md={4}>
                    <div className={styles.timeline}>
                        <div className={styles.timelineInner}>
                            {person.timeline && person.timeline.map((item) => (
                                <div
                                    className={`${styles.timelineItem} ${item.content === 'Unsubscribed from all communication' ? styles.timelineItemRed : ''}`}
                                >
                                    <div className={styles.timelineItemContent}>
                                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                    </div>
                                    <div className={styles.timelineItemMeta}>
                                        {item.date} | {item.user.firstName}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default PersonEditView;
