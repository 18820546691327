import { findIndex } from 'lodash';
import { faPlus, faPencil, faTrashAlt, faUpload, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Confirm, DropdownOption, Form, Menu, Message, Modal, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiPerson } from '../../../api/person';
import styles from './styles.module.scss';

const PersonListView: FC = (): JSX.Element => {
    const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ people, setPeople ] = useState<ApiPerson[]>([]);
    const [ errors, setErrors ] = useState<any>();
    const [ template, setTemplate ] = useState<string>('');
    const [ showModal, setShowModal ] = useState<boolean | string>(false);
    const [ templateOptions, setTemplateOptions ] = useState<DropdownOption[]>([]);

    useEffect(() => {
        if (showModal && templateOptions.length <= 0) {
            api.listTemplates({ limit: 999, manual: true }).then(({ data }) => {
                setTemplateOptions(data.data.map((item) => ({
                    text: item.name,
                    value: item.code,
                })))
            });
        }
    }, [showModal, templateOptions]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listPeople({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setPeople(data.data);
            setIsLoading(false);
        });
    }
    useEffect(() => fetch(), []);

    const deletePerson = (personId: string): void => {
        api.deletePerson(personId).then(() => {
            fetch();
            toast('Person deleted successfully');
        });
    }

    const sendLink = (emailAddress: string): void => {
        setShowModal(false);
        setTemplate('');
        api.sendConsentLink('609JGC', template, emailAddress, true).then(() => {
            toast('Link sent successfully');
        });
    }

    const handleFileUpload = (e: any): void => {
        setErrors(undefined);

        api.importPeople(e.target.files[0]).then((res) => {
            toast('Import succesfully uploaded and will run in the background.');
        }).catch(({ response }) => {
            if (response.data.errors.file) {
                toast('Invalid format, upload CSV or Excel', 'error');
            } else {
                toast('Errors found in file', 'error');
                setErrors(response.data.errors);
            }
        });
    }

    const handleSync = (person: ApiPerson): void => {
        if (person.isSynced) return;
        api.syncConsent(person.id).then(() => {
            const newPeople = [ ...people ];
            const index = findIndex(newPeople, { id: person.id });
            newPeople[index].isSynced = true;
            setPeople(newPeople);
            toast('Sync set succesfully');
        })
    }

    return (<>
        <PageHeader title="Consents" breadcrumb={{'/people': 'Overview'}}>
            <input
                accept=".csv, .xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileUpload}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
            />
            <Button
                icon={faUpload}
                label="Import"
                onClick={() => fileInputRef.current?.click()}
            />
            <Button
                href="/consents/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        {errors ? Object.keys(errors).map((line: any, index) => (
            <Message
                key={`err-${index}`}
                error
                title={`Foutmelding op regel ${line}`}
            >
                <ul>
                    {errors[line].map((o: string, i: number) => <li key={`err-${index}-${i}`}>{o}</li>)}
                </ul>
            </Message>
        )) : (<>
            <Segment isLoading={isLoading}>
                <Table.Actions
                    onSearch={fetch}
                    remember
                />
                <Table fluid>
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell collapsing />
                            <Table.HeaderCell>
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Email address
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                UCI
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing />
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </thead>
                    <tbody>
                        {people.length > 0 ? people.map((person) => (
                            <Table.Row key={`row-${person.id}`}>
                                <Table.Cell collapsing>
                                    <span className={`${styles.status} ${styles[`status--${person.status}`]}`} />
                                </Table.Cell>
                                <Table.Cell primary name="Name">
                                    <Link to={`/consents/${person.id}/edit`}>
                                        {person.name}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell name="Email address">
                                    {person.emailAddress}
                                </Table.Cell>
                                <Table.Cell name="UCI">
                                    {person.uci}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Checkbox
                                        toggle
                                        checked={person.isSynced}
                                        onChange={() => handleSync(person)}
                                    />
                                </Table.Cell>
                                <Table.Cell collapsing actions>
                                    <Menu dropdown>
                                        <Menu.Item
                                            icon={faPencil}
                                            content="Edit"
                                            href={`/consents/${person.id}/edit`}
                                        />
                                        <Menu.Item
                                            icon={faPaperPlane}
                                            content="Send link"
                                            onClick={() => setShowModal(person.emailAddress)}
                                        />
                                        <Menu.Divider />
                                        <Confirm
                                            content="Are you sure you wish to delete this person? This action cannot be undone"
                                            onConfirm={() => deletePerson(person.id)}
                                            trigger={<Menu.Item
                                                icon={faTrashAlt}
                                                content="Delete"
                                            />}
                                        />
                                    </Menu>
                                </Table.Cell>
                            </Table.Row>
                        )) : (
                            <Table.Row noResults />
                        )}
                    </tbody>
                </Table>
            </Segment>
            {meta && <Table.Pagination
                meta={meta}
                onChange={(page: number) => fetch('', page)}
            />}

            <Modal
                onClose={() => setShowModal(false)}
                open={showModal !== false}
                size="small"
                header="Choose template"
                footer={(
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            link
                            label="Cancel"
                        />
                        <Button
                            primary
                            disabled={template === ''}
                            label="Send link"
                            onClick={() => sendLink(showModal as string)}
                        />
                    </div>
                )}
            >
                <Form.Dropdown
                    label="Template"
                    onChange={({ value }) => setTemplate(value)}
                    options={templateOptions}
                    value={template}
                />
            </Modal>
        </>)}
    </>);
}

export default PersonListView;
